@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./assets/fonts/Poppins/Poppins-Regular.ttf') format('ttf');
}

/* @font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('ttf');
} */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./assets/fonts/Manrope/Manrope-Regular.ttf') format('ttf');
}

* {
  /* color: #fff; */
  font-family: 'Poppins', sans-serif;
}

body {
  background: linear-gradient(90deg,
      rgba(67, 49, 101, 1) 0%,
      rgba(16, 22, 31, 1) 35%,
      rgba(26, 32, 60, 1) 100%);
}

.text-gradient {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

@keyframes borderFlash {

  0%,
  100% {
    border-color: #775dda;
    /* Primary color */
  }

  50% {
    border-color: #968e9ea0;
    /* Secondary color */
  }
}

.border-flash {
  animation: borderFlash 2s infinite;
}

.border-flash:hover {
  animation: none;
  border-color: #775dda;
  /* Maintain primary color on hover */
}